import { useEffect, useState, MouseEvent, useRef, ChangeEvent } from 'react';
import { Modal } from 'react-bootstrap';
import gsap from 'gsap';
import imagesLoaded from 'imagesloaded';
import ScrollTrigger from 'gsap/ScrollTrigger';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import YouTubePlayer from 'youtube-player';
import Player from '@vimeo/player';
import { Navigation } from 'swiper/modules';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import BkgImageStory from '../assets/story_bkg.jpg';
import BkgImageWishlist from '../assets/wish_bkg.jpg';
import CenterDividerSvg from '../assets/center_divider.svg';
import CenterDivider2Svg from '../assets/center_divider_2.svg';
import BkgImageWish from '../assets/wishlist_logos.png';
import DividerBar from '../assets/divider.png';
import HeroSideBar from '../assets/hero_sidebars.png';
import StorySideBar from '../assets/story_sidebars.png';
import WishSideBar from '../assets/wishlist_sidebars.png';
import BkgImageSign from '../assets/sign_bkg.jpg';
import BarLine from '../assets/bar_line.svg';

import SwiperArrow from '../assets/slider_nav.png';
import Footer from '../components/Footer';
import GLightbox from 'glightbox';
import ScrollAnimation from '../tools/ScrollAnimation';
import { Header } from '../components/Header';
import { usePageProvider } from '../PageContext';
import { FetchPageData, GetCookie, ScrollToView, SetCookie } from '../tools/Helpers';
import { Link, useLocation } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

export interface EmailFormFields {
  EMAIL: string;
  tags: number;
}

export type DefaultFormFields = EmailFormFields;

interface SocialMessage {
  name: string;
  link: string;
  icon: string;
  position: string;
}

interface MenuMessage {
  title: string;
  url: string;
  attr_title: string;
  classes: any;
  target: string;
}

interface PageState {
  content: any;
}

const Home: React.FC = () => {
  const videoId = 1010372399;
  const heroRef = useRef(null);
  const InitEffect = useRef(false);
  const [videoLoop, setVideoLoop] = useState(false);
  const [pageData, setPageData] = useState<PageState>({ content: {} });
  const [newsData, setNewsData] = useState<PageState>({ content: {} });
  const [showTrailer, setShowTrailer] = useState(false);
  const [showAgeGate, setShowAgeGate] = useState(false);
  const [gateError, setGateError] = useState(false);
  const [email, setEmail] = useState('');
  const location = useLocation();
  const { content, pathname } = usePageProvider();

  const headerProps: {
    header: MenuMessage[];
    social: SocialMessage[];
    isReady: boolean;
  } = {
    header: content.header,
    social: content.social,
    isReady: content.isReady,
  };

  const footerProps: {
    footer: MenuMessage[];
    esrb: string;
    legal: string;
    isReady: boolean;
  } = {
    footer: content.footer,
    esrb: content.esrb,
    legal: content.legal,
    isReady: content.isReady,
  };

  const altTitle = 'Killing Floor 3 - Join the fight against unhumanity';
  const mailChimpUrl =
    'https://tripwireinteractive.us2.list-manage.com/subscribe/post?u=0b638f09c7c45666e46052ede&id=b8bfd5aafe';

  const HandleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setEmail(value);
  };

  const HandleShowTrailer = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    event.preventDefault();
    setShowTrailer(true);
  };

  const PlayTrailer = () => {
    const player = YouTubePlayer('player');
    const codeElement = document.getElementById('trailer-modal');
    if (codeElement) {
      const code = codeElement.dataset.code;
      if (code) {
        player.loadVideoById(code);
        player.playVideo();
      }
    }
  };

  const HandleCloseTrailer = () => setShowTrailer(false);

  const ValidateAge = (): void => {
    let fields: string[] = [];
    const ageGate = document.querySelectorAll<HTMLInputElement>('.age-gate');
    ageGate.forEach((input: HTMLInputElement) => {
      fields.push(input.value);
    });

    const today = new Date();
    const birthDate = new Date(Number(fields[2]), Number(fields[0]) - 1, Number(fields[1])); // Subtract 1 from month because months are zero-indexed in JavaScript
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    const day = today.getDate() - birthDate.getDate();
    if (month < 0 || (month === 0 && day < 0)) {
      age--;
    }

    if (age < 18) {
      setGateError(true);
      SetCookie('agegate_allow', 'failed');
    } else {
      setGateError(false);
      setShowAgeGate(false);
      SetCookie('agegate_allow', 'passed');
      document.body.setAttribute('style', 'overflow:inherit;');
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const ScrollController = new ScrollAnimation();

    // Allow AgeGate
    if (!GetCookie('agegate_allow')) {
      document.body.setAttribute('style', 'overflow:hidden;');
      window.scrollTo(0, 0);
      setShowAgeGate(true);
    } else if (GetCookie('agegate_allow') === 'failed') {
      document.body.setAttribute('style', 'overflow:hidden;');
      window.scrollTo(0, 0);
      setShowAgeGate(true);
      setGateError(true);
    } else {
      setShowAgeGate(false);
    }

    if (heroRef.current) {
      const videoPlayer = new Player(heroRef.current, {
        id: videoId,
        autoplay: true,
        loop: false,
        background: true,
        muted: true,
      });
      // Listen for when the video ends
      videoPlayer.on('timeupdate', data => {
        const currentTime = data.seconds;
        if (currentTime > 20 && !videoLoop) {
          setVideoLoop(true);
          videoPlayer
            .setCurrentTime(6)
            .then(() => {
              videoPlayer.play();
              setVideoLoop(false);
            })
            .catch((error: any) => {
              console.error('Error restarting video at 2 seconds:', error);
            });
        }
      });
    }

    if (!showAgeGate) {
      //console.log(location.pathname);
      if (location.pathname === '/media/') {
        setTimeout(function () {
          ScrollToView('media');
        }, 500);
      } else if (location.pathname === '/about/') {
        setTimeout(function () {
          ScrollToView('about');
        }, 500);
      } else if (location.pathname === '/') {
        setTimeout(function () {
          ScrollToView('root');
        }, 500);
      }
    }

    const fetchData = async () => {
      try {
        const responds = await FetchPageData('page-home', `wp/v2/pages?slug=home&timestamp=${new Date().getTime()}`);
        const respondsNews = await FetchPageData(
          'page-news-home',
          `wp/v2/news?order=desc&per_page=5&timestamp=${new Date().getTime()}`,
          false,
        );
        if (responds && responds.content && respondsNews && respondsNews.content) {
          setPageData(responds);
          setNewsData(respondsNews);
          startProcess();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const startProcess = () => {
      //console.log(newsData);
      const imgLoad = imagesLoaded('#news-page', { background: true });
      imgLoad.on('done', () => {
        // Handle Inview Area
        ScrollController.ScrollAnim({
          element: 'header',
          duration: '100%',
          trigger: 0.7,
          start: 'active',
        });
        ScrollController.ScrollAnim({
          element: '#news',
          duration: '100%',
          trigger: 0.7,
          toggle: true,
        });
        ScrollController.ScrollAnim({
          element: '#media',
          duration: '100%',
          trigger: 0.7,
          toggle: true,
        });
        ScrollController.ScrollAnim({
          element: '#wishlist',
          duration: '100%',
          trigger: 0.7,
          toggle: true,
        });
        setTimeout(function () {
          ScrollController.ScrollAnim({
            element: '#intro',
            duration: '100%',
            trigger: 0.7,
            toggle: true,
          });
          ScrollController.ScrollAnim({
            element: '#about',
            duration: '100%',
            trigger: 0.7,
            toggle: true,
            switch: { target: 'intro', class: 'is-scrolled' },
          });
          ScrollController.ScrollAnim({
            element: '#signup',
            duration: '100%',
            trigger: 0.7,
            toggle: true,
          });
        }, 500);

        // Scroll Animation Block
        gsap
          .timeline({ scrollTrigger: { trigger: '#intro', scrub: true, start: 'top top', end: 'bottom top' } })
          .fromTo('#video-hero', { scale: 1 }, { scale: 1.1, ease: 'none', duration: 1 }, '100%');

        gsap
          .timeline({ scrollTrigger: { trigger: '#about', scrub: true } })
          .to('#about-zoom', { scale: 1.5, ease: 'none', duration: 1 }, 0)
          .to('#bar-1', { duration: 0.8, scale: 1.2, opacity: 1, x: 0, ease: 'none' }, 0)
          .to('#bar-2', { duration: 0.8, scale: 1.2, opacity: 1, ease: 'none' }, 0)
          .to('#bar-3', { duration: 0.8, scale: 1.2, opacity: 1, x: 0, ease: 'none' }, 0);

        gsap
          .timeline({ scrollTrigger: { trigger: '#wishlist', scrub: true } })
          .to('#wishlist-zoom', { scale: 1.5, ease: 'none', duration: 0.5 });

        gsap
          .timeline({ scrollTrigger: { trigger: '#signup', scrub: true } })
          .to('#signup-zoom', { scale: 1.3, ease: 'none', duration: 1 });

        GLightbox({ moreLength: 0 });
      });
    };

    const Init = () => {
      window.scrollTo(0, 0);
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (process.env.NODE_ENV === 'development') {
      if (InitEffect.current) {
        const cleanup = Init();
        return cleanup;
      }
      return () => {
        InitEffect.current = true;
      };
    } else {
      Init();
    }
  }, [showAgeGate]);

  return (
    <>
      {showAgeGate && (
        <section id="agegate">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <div className="wrapper">
              <h3 className="title mb-4">Please, enter your birthdate.</h3>
              <form>
                <div className="form-group d-flex justify-content-between">
                  <input
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]{2}"
                    name="MM"
                    placeholder="MM"
                    min="01"
                    max="12"
                    maxLength={2}
                    className="form-control age-gate"
                    required
                  />
                  <input
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]{3}"
                    name="DD"
                    placeholder="DD"
                    min="01"
                    max="31"
                    maxLength={2}
                    className="form-control age-gate"
                    required
                  />
                  <input
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]{3}"
                    name="YYYY"
                    placeholder="YYYY"
                    max="9999"
                    maxLength={4}
                    className="form-control age-gate"
                    required
                  />
                </div>
                {gateError && (
                  <div className="alert alert-danger">
                    Sorry, but you're not permitted to view these materials at this time.
                  </div>
                )}
                {!gateError && (
                  <button type="button" className="btn btn-shape" onClick={ValidateAge}>
                    Enter
                  </button>
                )}
              </form>
              <div className="note">
                <p>
                  By entering this site you are agreeing to the
                  <a
                    href="https://www.tripwireinteractive.com/#/privacy-notice/#top\"
                    target="_blank"
                    rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
      <div id="home-page">
        <Header path={pathname} data={headerProps} />
        <section id="intro" style={{ backgroundColor: `#000` }}>
          <div className="video-wrapper">
            <iframe
              ref={heroRef}
              id="video-hero"
              title={altTitle}
              src={`https://player.vimeo.com/video/${videoId}?api=1&background=1&muted=1&autoplay=1&loop=0&byline=0&title=0`}
              width="100%"
              height="100%"
              allow="autoplay"
              data-ready="true"></iframe>
          </div>
          <div className="bars-wrapper">
            <img className="sidebar" src={HeroSideBar} alt={altTitle} />
            <img className="sidebar" src={HeroSideBar} alt={altTitle} />
          </div>
          <div className="d-flex align-items-end justify-content-center h-100">
            <div className="layer w-100">
              <div className="container">
                {pageData.content && pageData.content.title && (
                  <h1 className="heading inview animated delay-1">{pageData.content.title.rendered}</h1>
                )}
                <div className="center-bar my-4 inview animated delay-2">
                  <img className="img-fluid" src={CenterDividerSvg} alt={altTitle} />
                </div>
                <div className="action-buttons my-3 inview animated delay-4">
                  <a className="svg-btn" href="#trailer" title="Play Trailer" onClick={HandleShowTrailer}>
                    <svg className="img-fluid" width="182px" height="54px" viewBox="0 0 182 54">
                      <path
                        fillRule="evenodd"
                        fill="rgb(211, 0, 0)"
                        d="M24.474,53.092 L0.407,29.126 L0.407,0.367 L181.222,0.367 L181.222,53.092 L24.474,53.092 Z"
                      />
                      <text
                        transform="translate(46 34)"
                        fontSize="1.6rem"
                        fontFamily="Kanit"
                        fontStyle="italic"
                        fill="white">
                        TRAILER
                      </text>
                    </svg>
                  </a>
                  {pageData.content && pageData.content.acf && (
                    <a className="svg-btn svg-mobile" href="#wishlist" title={pageData.content.acf.wishlist_title}>
                      <svg className="img-fluid desktop" width="182px" height="54px" viewBox="0 0 182 54">
                        <path
                          fillRule="evenodd"
                          fill="rgb(211, 0, 0)"
                          d="M0.407,0.367 L181.222,0.367 L181.222,53.092 L0.407,53.092 L0.407,0.367 Z"
                        />
                        <text
                          transform="translate(20 34)"
                          fontSize="1.6rem"
                          fontFamily="Kanit"
                          fontStyle="italic"
                          fill="white">
                          {pageData.content && pageData.content.acf && pageData.content.acf.wishlist_title}
                        </text>
                      </svg>
                      <svg className="img-fluid mobile" width="182px" height="54px" viewBox="0 0 182 54">
                        <path
                          fillRule="evenodd"
                          fill="rgb(211, 0, 0)"
                          d="M156.637,52.819 L0.215,52.819 L0.215,0.367 L180.654,0.367 L180.654,28.977 L156.637,52.819 Z"
                        />
                        <text
                          transform="translate(18 34)"
                          fontSize="1.6rem"
                          fontFamily="Kanit"
                          fontStyle="italic"
                          fill="white">
                          {pageData.content && pageData.content.acf && pageData.content.acf.wishlist_title}
                        </text>
                      </svg>
                    </a>
                  )}
                  <a className="svg-btn svg-mobile bottom" href="#signup" title="Sign Up">
                    <svg className="img-fluid desktop" width="182px" height="54px" viewBox="0 0 182 54">
                      <path
                        fillRule="evenodd"
                        fill="rgb(211, 0, 0)"
                        d="M156.637,52.819 L0.215,52.819 L0.215,0.367 L180.654,0.367 L180.654,28.977 L156.637,52.819 Z"
                      />
                      <text
                        transform="translate(43 34)"
                        fontSize="1.6rem"
                        fontFamily="Kanit"
                        fontStyle="italic"
                        fill="white">
                        SIGN UP
                      </text>
                    </svg>
                    <svg className="img-fluid mobile" width="207px" height="54px" viewBox="0 0 207 54">
                      <path
                        fillRule="evenodd"
                        fill="rgb(211, 0, 0)"
                        d="M206.594,29.097 L182.524,53.043 L181.222,53.042 L181.222,53.092 L24.474,53.092 L0.407,29.126 L0.407,0.367 L1.000,0.367 L1.000,0.000 L206.000,0.000 L206.000,0.347 L206.594,0.347 L206.594,29.097 Z"
                      />
                      <text
                        transform="translate(58 34)"
                        fontSize="1.6rem"
                        fontFamily="Kanit"
                        fontStyle="italic"
                        fill="white">
                        SIGN UP
                      </text>
                    </svg>
                  </a>
                </div>
              </div>
              <img className="img-fluid w-100" src={DividerBar} alt={altTitle} />
            </div>
          </div>
        </section>
        <section id="about">
          <div id="about-zoom" className="bkg-zoom" style={{ backgroundImage: `url(${BkgImageStory})` }}></div>
          <div className="bars-wrapper">
            <img id="bar-1" className="bars" src={require(`../assets/bar_1.png`)} alt={altTitle} />
            <img id="bar-2" className="bars" src={require(`../assets/bar_2.png`)} alt={altTitle} />
            <img id="bar-3" className="bars" src={require(`../assets/bar_3.png`)} alt={altTitle} />
            <img id="about-left-bar" className="sidebar" src={StorySideBar} alt={altTitle} />
            <img id="about-right-bar" className="sidebar" src={StorySideBar} alt={altTitle} />
          </div>
          <div className="overlay">
            <div className="container">
              {pageData.content && pageData.content.acf && (
                <h2 className="heading inview animated mb-4">{pageData.content.acf.about_title}</h2>
              )}
              {pageData.content && pageData.content.content && (
                <div
                  className="inview animated delay-2"
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.acf.about_content,
                  }}
                />
              )}
              <div className="center-bar inview animated delay-3">
                <img className="img-fluid" src={CenterDivider2Svg} alt={altTitle} />
              </div>
            </div>
          </div>
        </section>
        <section id="news">
          <div className="news-wrapper">
            <h2>
              <span className="heading inview animated">News</span>
            </h2>
            <div className="swiper-block">
              <SwiperComponent
                spaceBetween={10}
                slidesPerView={3}
                speed={800}
                navigation={{
                  nextEl: '.swiper-next-news-arrow',
                  prevEl: '.swiper-prev-news-arrow',
                }}
                modules={[Navigation]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  499: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                }}>
                {newsData &&
                  Array.isArray(newsData.content) &&
                  newsData.content.map((news: any, key: number) => (
                    <SwiperSlide key={key}>
                      <div className="card h-100">
                        <Link to={`${news.link_relative}`}>
                          {news.acf.preview_image && (
                            <div className="card-image">
                              <img
                                src={news.acf.preview_image['url']}
                                className="img-fluid"
                                alt={news.title.rendered}
                              />
                            </div>
                          )}
                        </Link>
                        <div className="card-body">
                          <div className="date">{news.date_friendly}</div>
                          <Link to={`${news.link_relative}`}>
                            <h4>{news.title.rendered}</h4>
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </SwiperComponent>
              <div className="swiper-navigation">
                <div className="swiper-arrows swiper-prev-news-arrow">
                  <img className="img-fluid" src={SwiperArrow} alt={altTitle} />
                </div>
                <div className="swiper-arrows swiper-next-news-arrow">
                  <img className="img-fluid" src={SwiperArrow} alt={altTitle} />
                </div>
              </div>
            </div>

            <Link className="btn btn-cta" to="/news/">
              READ MORE
            </Link>
          </div>
        </section>
        <section id="media">
          <div className="media-wrapper">
            <h2>
              <span className="heading inview animated">Media</span>
            </h2>
            <div className="swiper-block">
              <SwiperComponent
                spaceBetween={10}
                slidesPerView={3}
                loop
                speed={800}
                navigation={{
                  nextEl: '.swiper-next-arrow',
                  prevEl: '.swiper-prev-arrow',
                }}
                modules={[Navigation]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  499: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                }}>
                {pageData.content &&
                  pageData.content.acf &&
                  pageData.content.acf.media_gallery.map(function (slide: any, key: number) {
                    return (
                      <SwiperSlide key={key}>
                        <a href={slide.url} className="glightbox" data-gallery="Screenshots" data-type="image">
                          <img className="img-fluid" src={slide.sizes.large} alt={slide.title} title={slide.title} />
                        </a>
                      </SwiperSlide>
                    );
                  })}
              </SwiperComponent>
              <div className="swiper-navigation">
                <div className="swiper-arrows swiper-prev-arrow">
                  <img className="img-fluid" src={SwiperArrow} alt={altTitle} />
                </div>
                <div className="swiper-arrows swiper-next-arrow">
                  <img className="img-fluid" src={SwiperArrow} alt={altTitle} />
                </div>
              </div>
            </div>
          </div>
          <img className="divider img-fluid w-100" src={DividerBar} alt={altTitle} />
        </section>
        <section id="wishlist">
          <div id="wishlist-zoom" className="bkg-zoom" style={{ backgroundImage: `url(${BkgImageWishlist})` }}></div>
          <div className="bars-wrapper">
            <img className="sidebar" src={WishSideBar} alt={altTitle} />
            <img className="sidebar" src={WishSideBar} alt={altTitle} />
          </div>
          <div className="layer">
            <div className="container">
              <div className="wishlist-selection">
                <div className="wish-block">
                  <img className="img-fluid" src={BkgImageWish} alt={altTitle} />
                </div>
                {pageData.content && pageData.content.acf && (
                  <h2 className="heading inview animated">{pageData.content.acf.wishlist_title}</h2>
                )}
                <div className="wrapper d-flex align-items-center justify-content-center">
                  {pageData.content.acf &&
                    Array.isArray(pageData.content.acf.wishlist_stores) &&
                    pageData.content.acf.wishlist_stores.map((store: any, key: number) => (
                      <span title={store.title} key={key}>
                        <a
                          href={store.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          title={store.title}
                          dangerouslySetInnerHTML={{
                            __html: store.svg,
                          }}
                        />
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="signup">
          <div className="bar-line">
            <img className="img-fluid" src={BarLine} alt={altTitle} />
          </div>
          <div id="signup-zoom" className="bkg-zoom" style={{ backgroundImage: `url(${BkgImageSign})` }}></div>
          <div className="signup-wrapper">
            {pageData.content && pageData.content.acf && (
              <h3 className="heading inview animated delay-2">{pageData.content.acf.signup_title}</h3>
            )}
            <h4 className="mt-4 inview animated delay-2">
              {pageData.content && pageData.content.acf && (
                <span
                  className="inview animated delay-2"
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.acf.signup_content,
                  }}
                />
              )}
            </h4>
            <div className="position-relative inview animated delay-3">
              <MailchimpSubscribe<DefaultFormFields>
                render={hooks => (
                  <>
                    <form
                      className="row"
                      onSubmit={e => {
                        e.preventDefault();
                        hooks.subscribe({
                          EMAIL: email,
                          tags: 32087,
                        });
                      }}>
                      <div className="col-auto shape">
                        <svg className="img-fluid desktop" width="411px" height="63px" viewBox="0 0 411 63">
                          <path
                            fillRule="evenodd"
                            strokeWidth="2px"
                            stroke="rgb(127, 0, 0)"
                            fill="rgb(0, 0, 0)"
                            d="M29.475,57.725 L5.407,33.758 L5.407,5.000 L405.222,5.000 L405.222,57.725 L29.475,57.725 Z"
                          />
                        </svg>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email Address"
                          aria-label="Email Address"
                          value={email}
                          onChange={HandleOnChange}
                          required
                        />
                      </div>
                      <div className="col-auto">
                        <button type="submit" className="svg-btn svg-mobile bottom" title="Sign Up">
                          <svg className="img-fluid desktop" width="182px" height="54px" viewBox="0 0 182 54">
                            <path
                              fillRule="evenodd"
                              fill="rgb(211, 0, 0)"
                              d="M156.637,52.819 L0.215,52.819 L0.215,0.367 L180.654,0.367 L180.654,28.977 L156.637,52.819 Z"
                            />
                            <text
                              transform="translate(43 34)"
                              fontSize="1.6rem"
                              fontFamily="Kanit"
                              fontStyle="italic"
                              fill="white">
                              SIGN UP
                            </text>
                          </svg>
                          <svg className="img-fluid mobile" width="209px" height="54px" viewBox="0 0 209 54">
                            <path
                              fillRule="evenodd"
                              fill="rgb(211, 0, 0)"
                              d="M184.524,53.094 L27.781,53.094 L27.781,53.092 L24.474,53.092 L0.407,29.126 L0.407,0.367 L181.222,0.367 L181.222,0.375 L208.594,0.375 L208.594,29.125 L184.524,53.094 Z"
                            />
                            <text
                              transform="translate(58 34)"
                              fontSize="1.6rem"
                              fontFamily="Kanit"
                              fontStyle="italic"
                              fill="white">
                              SIGN UP
                            </text>
                          </svg>
                        </button>
                      </div>
                    </form>
                    {hooks.status === 'error' && (
                      <div className="alert alert-danger">
                        There's seem to be a problem. Please &nbsp;
                        {typeof hooks.message === 'string'
                          ? hooks.message.replace(/0 - /g, '')
                          : hooks.message instanceof Error
                            ? hooks.message.message.replace(/0 - /g, '')
                            : ''}
                      </div>
                    )}
                    {hooks.status === 'success' && <div className="alert alert-success">{hooks.message}</div>}
                  </>
                )}
                url={mailChimpUrl}
              />
            </div>
          </div>

          <div className="position-relative mt-5">
            <img className="divider img-fluid w-100" src={DividerBar} alt={altTitle} />
          </div>
        </section>
        <Footer data={footerProps} />
      </div>
      {pageData.content && pageData.content.acf && (
        <Modal
          id="trailer-modal"
          show={showTrailer}
          onHide={HandleCloseTrailer}
          onEntered={PlayTrailer}
          size="xl"
          centered
          data-code={pageData.content.acf.trailer_code}>
          <Modal.Header>
            <button
              type="button"
              className="btn-close btn-close-white"
              aria-label="Close"
              onClick={HandleCloseTrailer}></button>
          </Modal.Header>
          <Modal.Body>
            <div className="ratio ratio-16x9">
              <div id="player" />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Home;
