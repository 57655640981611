import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Footer from '../components/Footer';
import { usePageProvider } from '../PageContext';
import { useEffect, useRef, useState } from 'react';
import { Header } from '../components/Header';
import imagesLoaded from 'imagesloaded';
import ScrollAnimation from '../tools/ScrollAnimation';
import { FetchPageData } from '../tools/Helpers';
import { Link } from 'react-router-dom';

import HeroSideBar from '../assets/hero_sidebars.png';
import CenterDividerSvg from '../assets/center_divider_gray.svg';
const altTitle = 'Killing Floor 3 - Join the fight against unhumanity';

gsap.registerPlugin(ScrollTrigger);

interface SocialMessage {
  name: string;
  link: string;
  icon: string;
  position: string;
}

interface MenuMessage {
  title: string;
  url: string;
  attr_title: string;
  classes: any;
  target: string;
}

interface PageState {
  content: any;
}

const News: React.FC = () => {
  const InitEffect = useRef(false);
  const [pageData, setPageData] = useState<PageState>({ content: {} });
  const [newsData, setNewsData] = useState<PageState>({ content: {} });
  const { content, pathname } = usePageProvider();

  const headerProps: {
    header: MenuMessage[];
    social: SocialMessage[];
    isReady: boolean;
  } = {
    header: content.header,
    social: content.social,
    isReady: content.isReady,
  };

  const footerProps: {
    footer: MenuMessage[];
    esrb: string;
    legal: string;
    isReady: boolean;
  } = {
    footer: content.footer,
    esrb: content.esrb,
    legal: content.legal,
    isReady: content.isReady,
  };

  useEffect(() => {
    const ScrollController = new ScrollAnimation();
    const fetchData = async () => {
      try {
        const responds = await FetchPageData('page-news', `wp/v2/pages?slug=news&timestamp=${new Date().getTime()}`);
        const newsPosts = await FetchPageData('news', `wp/v2/news?order=desc&timestamp=${new Date().getTime()}`, false);
        if (responds && responds.content && newsPosts && newsPosts.content) {
          setPageData(responds);
          setNewsData(newsPosts);
          startProcess();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const startProcess = () => {
      const imgLoad = imagesLoaded('#news-page', { background: true });
      imgLoad.on('done', () => {
        // Handle Inview Area
        ScrollController.ScrollAnim({
          element: 'header',
          duration: '100%',
          trigger: 0.7,
          start: 'active',
        });
        ScrollController.ScrollAnim({
          element: '#content-page',
          duration: '100%',
          trigger: 0.7,
          toggle: true,
        });
      });
    };
    const Init = () => {
      window.scrollTo(0, 0);
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (process.env.NODE_ENV === 'development') {
      if (InitEffect.current) {
        const cleanup = Init();
        return cleanup;
      }
      return () => {
        InitEffect.current = true;
      };
    } else {
      Init();
    }
  }, [pathname]);

  return (
    <>
      <div id="news-page">
        <Header path={pathname} data={headerProps} />
        <div className="bars-wrapper">
          <img className="sidebar" src={HeroSideBar} alt={altTitle} />
          <img className="sidebar" src={HeroSideBar} alt={altTitle} />
        </div>
        <div id="content-page" className="container-xl">
          {pageData.content && pageData.content.title ? (
            <h1 className="inview animated">{pageData.content.title.rendered}</h1>
          ) : (
            ''
          )}
          <div className="center-bar text-center my-4">
            <img className="img-fluid" src={CenterDividerSvg} alt={altTitle} />
          </div>
          <section id="news" className="inview animated delay-1 pt-2">
            {newsData && Array.isArray(newsData.content) && (
              <div className="row">
                <div className="col-md-7">
                  <div className="card card-featured">
                    <Link to={`${newsData.content[0].link_relative}`}>
                      {newsData.content[0].acf.featured_image && (
                        <div className="card-image">
                          <img
                            src={newsData.content[0].acf.featured_image['url']}
                            className="img-fluid"
                            alt={newsData.content[0].title.rendered}
                          />
                        </div>
                      )}
                    </Link>
                    <div className="overlay card-body">
                      <Link to={`${newsData.content[2].link_relative}`}>
                        <h3>{newsData.content[0].title.rendered}</h3>
                      </Link>
                      <div className="date">{newsData.content[0].date_friendly}</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card">
                    <Link to={`${newsData.content[1].link_relative}`}>
                      {newsData.content[0].acf.preview_image && (
                        <div className="card-image">
                          <img
                            src={newsData.content[1].acf.preview_image['url']}
                            className="img-fluid"
                            alt={newsData.content[1].title.rendered}
                          />
                        </div>
                      )}
                    </Link>
                    <div className="overlay card-body">
                      <Link to={`${newsData.content[2].link_relative}`}>
                        <h3>{newsData.content[1].title.rendered}</h3>
                      </Link>
                      <div className="date">{newsData.content[0].date_friendly}</div>
                    </div>
                  </div>
                  <div className="card card-last mt-4">
                    <Link to={`${newsData.content[2].link_relative}`}>
                      {newsData.content[0].acf.preview_image && (
                        <div className="card-image">
                          <img
                            src={newsData.content[2].acf.preview_image['url']}
                            className="img-fluid"
                            alt={newsData.content[2].title.rendered}
                          />
                        </div>
                      )}
                    </Link>
                    <div className="overlay card-body">
                      <Link to={`${newsData.content[2].link_relative}`}>
                        <h3>{newsData.content[2].title.rendered}</h3>
                      </Link>
                      <div className="date">{newsData.content[0].date_friendly}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <hr />
            <div id="news-post" className="mb-5">
              <div className="row row-cols-1 row-cols-md-3 g-3">
                {newsData &&
                  Array.isArray(newsData.content) &&
                  newsData.content.length > 3 &&
                  newsData.content.slice(3).map((news: any, index: number) => (
                    <div className={`col`} key={index}>
                      <div className="card h-100">
                        <Link to={`${news.link_relative}`}>
                          {news.acf.preview_image && (
                            <div className="card-image">
                              <img
                                src={news.acf.preview_image['url']}
                                className="img-fluid"
                                alt={news.title.rendered}
                              />
                            </div>
                          )}
                        </Link>
                        <div className="card-body">
                          <div className="date">{news.date_friendly}</div>
                          <Link to={`${news.link_relative}`}>
                            <h4>{news.title.rendered}</h4>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        </div>
        <Footer data={footerProps} />
      </div>
    </>
  );
};

export default News;
